@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import Cormorant Upright */
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&display=swap');

/* Import Poppins */
@import url('https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic');

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	scroll-behavior: smooth;
}

body {
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Cormorant Upright', serif;
    font-weight: 700;
}

.text-cormorant {
	font-family: 'Cormorant Upright', serif;
    font-weight: 600;

}


@layer components {
    .contactIcon {
        @apply text-2xl mb-2 text-red-500
    }
    .contactInput {
        @apply w-full px-6 bg-transparent text-base resize-none  border-0 border-b-2 border-red-100 focus:ring-0 focus:border-red-300 relative pl-10 
    }
    .inputIcon {
        @apply absolute bottom-[.5rem] left-2 text-2xl text-red-400
    }
}

::-webkit-scrollbar {
    width: 5px;
    background-color: #fff;
}
::-webkit-scrollbar:hover {
    width: 10px;
    /* background-color: #fff; */
}

::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: #F66A6A;
    border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #F25252;
    width: 10px;

}

.img-container::before {
    content: '';
    width: 100%;
    height: 100%;
    background: red;
    position: absolute;
    top: -0.25rem;
    right: -0.5rem;
    border-radius: 50%;
  }

  